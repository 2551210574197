import axios from 'axios';

export const API = axios.create({
    // Separe dev and deployement
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4880/api' : 'https://aaronlem.ovh/api',
    withCredentials: true,
    xsrfCookieName: 'aaron_token'
});

export const getUser = () => {
    return API.get('/user/discord')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error getting user:', error);
        });
}

export const accountOptions = (data) => {
    return API.put('/account', data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error getting account options:', error);
        });
}

export const deleteAccount = () => {
    API.delete('/account')
        .then(() => {
            console.log('Account deleted');
        })
        .catch((error) => {
            console.error('Error deleting account:', error);
        });
}

export const replaceToken = (token) => {
    API.defaults.headers.common['Authorization'] = "Bearer " + token;
}

export const logout = () => {
    API.defaults.headers.common['Authorization'] = '';
}