import { createRouter, createWebHistory } from 'vue-router'
import PageNotFound from '../views/PageNotFound.vue'
import Home from '../views/Home.vue'
import Commandes from '../views/Commandes.vue'
import CGU from '../views/CGU.vue'
import Player from '../views/Player.vue'
import DiscordAuth from '../views/SSO/Discord.vue'
import NationsGloryAuth from '../views/SSO/NationsGlory.vue'
import Profile from '../views/Profile.vue'
import store from '../store/index.js'

// If serve => localhost else aaronlem.ovh
const base_redirect = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://aaronlem.ovh'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'Aaron Le Mercenaire - Bot Discord'
        }
    },
    {
        path: '/commandes',
        name: 'commandes',
        component: Commandes,
        meta: {
            title: 'Aaron Le Mercenaire - Commandes'
        }
    },
    {
        path: '/cgu',
        name: 'cgu',
        component: CGU,
        meta: {
            title: 'Aaron Le Mercenaire - CGU'
        }
    },
    {
        path: '/player',
        name: "player",
        component: Player,
        meta: {
            title: 'Aaron Le Mercenaire - Joueur'
        }
    },
    {
        path: '/profile',
        name: "profile",
        component: Profile,
        meta: {
            title: 'Aaron Le Mercenaire - Profil',
            requiresAuth: true
        },
        children: [
            {
                path: 'nationsglory',
                name: "profile-nationsglory",
                component: Profile,
                meta: {
                    title: 'Aaron Le Mercenaire - Profil NationsGlory',
                    requiresAuth: true
                }
            },
            {
                path: 'add',
                name: "profile-add",
                component: Profile,
                meta: {
                    title: 'Aaron Le Mercenaire - Ajouter un joueur',
                    requiresAuth: true
                }
            },
            {
                path: 'guilds/:id/:tab',
                name: "profile-guilds",
                component: Profile,
                meta: {
                    title: 'Aaron Le Mercenaire - Configuration de guilde',
                    requiresAuth: true
                },
                props: route => ({ id: route.params.id, tab: route.params.tab }) // Passe l'ID de la guilde comme propriété
            },
        ]
    },
    {
        path: '/docs',
        name: "api-docs",
        redirect: "/docs"
    },
    {
        path: '/login',
        name: "Login",
        meta: {
            title: 'Aaron Le Mercenaire - Authentification redirect'
        },
        beforeEnter(to, from, next) {
            const uri = `${base_redirect}/auth/discord`
            // Put the full page URL including the protocol http(s) below
            window.location.replace(`https://aaronlem.ovh/api/oauth/linked-role`)
        }
    },
    {
        path: '/nationsglory/login',
        name: "LoginNationsGlory",
        meta: {
            title: 'Aaron Le Mercenaire - Authentification NationsGlory redirect'
        },
        beforeEnter(to, from, next) {
            // Put the full page URL including the protocol http(s) below
            const uri = `${base_redirect}/auth/nationsglory`
            window.location.replace(`https://publicapi.nationsglory.fr/oauth/auth?client_id=CLIENTID_a9lBkU71woH9obwJrHl77Yka6ORvXKfw502fe3ee7d4ae4cb8b39b11a0cef824a&redirect_uri=${uri}`)
        }
    },
    {
        path: '/invite',
        name: "invite",
        meta: {
            title: 'Aaron Le Mercenaire - Inviter le bot'
        },
        beforeEnter(to, from, next) {
            window.location.replace("https://discord.com/api/oauth2/authorize?client_id=816374380456050739&permissions=11006233681&scope=bot%20applications.commands")
        }
    },
    {
        path: '/auth/discord',
        name: "auth-discord",
        meta: {
            title: 'Aaron Le Mercenaire - Authentification avec Discord'
        },
        component: DiscordAuth
    },
    {
        path: '/auth/nationsglory',
        name: "auth-nationsglory",
        meta: {
            title: 'Aaron Le Mercenaire - Authentification avec NationsGlory',
            requiresAuth: true
        },
        component: NationsGloryAuth
    },
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        meta: {
            title: 'Aaron Le Mercenaire - Introuvable'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (savedPosition) return savedPosition;

        return {
            el: '.topnav',
            behavior: 'smooth'
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta?.title)
        document.title = to.meta.title;
    next();
})

// Guard pour vérifier si l'utilisateur est connecté
router.beforeEach((to, from, next) => {
    // Vérifier si la route requiert une authentification
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // Vérifiez si l'utilisateur est connecté (par exemple, via un token JWT dans localStorage)
        // get from state
        const isAuthenticated = store.getters.logged

        if (!isAuthenticated) {
            // Si l'utilisateur n'est pas connecté, le rediriger vers la page de connexion
            next({ name: 'Login' });
        } else {
            // Sinon, il peut accéder à la page
            next();
        }
    } else {
        // Si la route ne nécessite pas d'authentification, laisser l'utilisateur continuer
        next();
    }
});


export default router